<template>
	<GridButton
		tag-name="button"
		:content="content"
		:type="type"
		@click.prevent
		@drag.prevent
		@dragstart.prevent
	/>
</template>

<script>
import GridButton from '@user/components/grid-components/button/GridButton.vue';
import { useGridButton } from '@user/components/grid-components/button/useGridButton';

export default {
	name: 'GridStripeButtonProviderBuilder',
	components: { GridButton },
	props: {
		data: {
			type: Object,
			required: true,
		},
	},
	setup(props) {
		const {
			content,
			type,
		} = useGridButton(props);

		return {
			content,
			type,
		};
	},
};
</script>
